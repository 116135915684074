import React from "react"

import PageLayout from "../components/new-pagelayout"
import { graphql } from "gatsby"
//import * as blogPostPageStyles from "./blogpostpage.module.scss"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        author
      }
      html
    }
  }
`

const BlogPostPage = props => {
  const {
    frontmatter: { title, date, author },
    html,
  } = props.data.markdownRemark
  return (
    <PageLayout pageTitle={`Blog: ${title}`}>
      <section class="py-20">
        <div class="container px-4 mx-auto">
          <div class="max-w-2xl mx-auto">
            <h2 class="text-3xl font-semibold font-heading">{title}</h2>
            <p class="mb-10 text-s text-blue-400 font-semibold">
              {author} – {date}
            </p>
            {/*
            <div class="h-112 mb-10">
              <img
                class="w-full h-full object-cover rounded-lg"
                src="https://images.unsplash.com/photo-1524508762098-fd966ffb6ef9?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                alt=""
              ></img>
            </div>
            <p class="mb-6 text-xl text-gray-500">
              These types of questions led me to miss numerous deadlines, and I
              wasted time and energy in back-and-forth communication. Sadly,
              this situation could have been avoided if the wireframes had
              provided enough detail.
            </p>
            */}
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
      </section>
      {/*
      <div className={blogPostPageStyles.frontmatter}>
        <h1>{title}</h1>
        <p>
          {author} – {date}
        </p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
      */}
    </PageLayout>
  )
}

export default BlogPostPage
